.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
    text-align: left;

    .about-text-zone {
      p {
        position: initial;
        font-size: 15px;
        color: #fff;
        font-family: sans-serif;
        font-weight: 300;
        min-width: fit-content;
        animation: pulse 1s;

        &:nth-of-type(1) {
          animation-delay: 1.1s;
        }

        &:nth-of-type(2) {
          animation-delay: 1.2s;
        }

        &:nth-of-type(3) {
          animation-delay: 1.3s;
        }
      }

      .standout-text {
        font-size: 20px;
        font-weight: 400;
        color: #ffd700;
      }
    }
  }

  h1 {
    color: #fff;
    font-size: 53px;
    margin: 0;
    font-family: "Coolvetica", sans-serif;
    font-weight: 400;

    img {
      width: 62px;
      margin-left: 20px;
      opacity: 0;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }
}

h2 {
  color: #8d8d8d;
  margin-top: 20px;
  font-weight: 40;
  font-size: 12px;
  font-family: sans-serif;
  letter-spacing: 3px;
  animation: fadeIn 1s 1.8s backwards;
}

.flat-button {
  color: #ffd700;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid #ffd700;
  margin-top: 25px;
  float: left;
  animation: fadeIn 1s 1.8s backwards;
  white-space: nowrap;

  &:hover {
    background: #ffd700;
    color: #333;
  }
}

@media screen and (max-width: 1200px) {
  .home-page {
    .text-zone {
      position: initial;
      width: 100%;
      transform: none;
      padding: 10px;
      box-sizing: border-box;
    }

    .flat-button {
      float: none;
      display: block;
      margin: 20px auto 0 auto;
      width: 125px;
    }

    .logo-container {
      position: relative;
      width: 100px;
      height: auto;
      top: 50px;
      right: 0;
      box-sizing: border-box;
      margin: auto;
      left: 0;

      svg {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
      }
    }
  }
}
